.testimonials-section{
  background-attachment: fixed;
  padding: rem(40px 0 60px 0);
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down(md) {
    background-attachment: scroll;
  }
  h4{
    color: cl(white);
  }
  .block-ttl{
    .second-line{
      color: cl(white);
    }
  }
  .testim-slider{
    margin-top: 2.5rem;
    .slick-dots {
      position: relative;
      bottom: 0;
      display: flex;
      justify-content: center;
      padding-right: 15px;
      padding-left: 15px;
      margin: 0;
      li {
        list-style: none;
        margin:rem(0 12px 0 0);
        padding: 0;
        &:before{
          display: none;
        }
        button {
          border:0;
          width: rem(10px);
          height: rem(10px);
          padding: 0;
          overflow: hidden;
          text-indent: -999px;
          border-radius: 50%;
          flex-shrink: 0;
          background: #afafaf;
          &:focus{
            outline: none;
          }
        }
        &.slick-active {
          button {
            background: cl(primary);
          }
        }
      }
    }
    .item{
      .item-inner{
        width: rem(894px);
        max-width: 100%;
        padding: 0 1rem;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: rem(25px);
        img{
          width: rem(78px);
          height: rem(78px);
          object-fit: contain;
          margin-bottom: 1.25rem;
        }
        p{
          color: cl(white);
          font-size: rem(16px);
          line-height: rem(26px);
          font-weight: 400;
          margin-bottom: 2rem;
          text-align: center;
        }
        .name{
          color: cl(primary);
          @extend .ttu;
          font-size: rem(16px);
          line-height: rem(26px);
          font-weight: 700;
          text-align: center;
        }
      }

    }
  }
}