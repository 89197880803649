
$font: 'Gotham';
$font-b: 'bhatoshine';


$min-aspect169 : "(min-aspect-ratio: 16/9)";
$max-aspect169 : "(max-aspect-ratio: 16/9)";

$colors_theme : (
        "primary"   : "primary",
        "secondary" : "secondary",
        "text" : "text",
        "gray" : "gray",
        "black" : "black",
        "white" : "white",
        "gray-light": "gray-light",
        "yellow-bg" : "yellow-bg",
        "text-red" : "text-red",
        "text-yellow" : "text-yellow",
);

$colors : (
        "primary"  :#f8c502,
        "secondary" : #ef4123,
        "text-red" : #cc4b36,
        "text-yellow" : #f8c502,
        "text"  : #777777,
        "gray"  : #777777,
        "gray-light"  : #f8f8f8,
        "white" : #FFFFFF,
        "black" : #000000,
        "yellow-bg" :#ffcb05,
);

.color {
  @each $status, $color in $colors {
    &--#{$status} {
      color: $color;
    }
  }
}

@function HexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}

@function cl($name, $opacity: false) {
  @if $opacity {
    @return rgba(var(--color-#{unquote($name)}--rgb), $opacity);
  } @else {
    @return var(--color-#{unquote($name)});
  }
}

:root{
  @if $colors {
    @if $colors_theme {
      @each $key, $value in $colors_theme {
        --color-#{$key} : var(--color-#{$value});
        --color-#{$key}--rgb : var(--color-#{$value}--rgb);
      }
    }

    @each $key, $value in $colors {
      --color-#{$key} : #{$value};
      --color-#{$key}--rgb : #{HexToRGB($value)};
    }
  }
}