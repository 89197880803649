.btn {
  @extend .inline-b;
  @extend .ttu;
  background: cl(primary);
  color:cl(white);
  font-size: rem(16px);
  line-height: rem(48px);
  font-family: $font;
  font-weight: bold;
  font-style: normal;
  border: rem(1px solid cl(primary));
  cursor: pointer;
  border-radius: rem(2px);
  padding: rem(0 30px);
  letter-spacing: 0.15em;
  outline: 0;
  white-space: nowrap;
  &:hover,
  &:focus {
    border-color:cl(primary);
    background: cl(primary, 0.9);
    color:cl(white);
    text-decoration: none;
    outline:none;
  }
  &--primary-outline {
    background: none;
    color:cl(primary);
    border-color:cl(primary);
    &:hover,
    &:focus  {
      background: cl(primary, 0.1);
      color:cl(primary);
      border-color:cl(primary);
    }
  }
  &.w-220{
    min-width: rem(220px);
  }
  &--black {
    background: cl(black);
    border-color:cl(black);
    &:hover,
    &:focus  {
      background: cl(black, .8);
    }
  }
  &--secondary {
    background: cl(secondary);
    color: cl(white);
    border-color:cl(secondary);
    &:hover,
    &:focus  {
      background: cl(secondary, .8);
      border-color:cl(secondary);
      color:cl(white);
    }
  }
  &--secondary-outline {
    background: none;
    color:cl(secondary);
    border-color:cl(secondary);
    &:hover,
    &:focus  {
      color:cl(secondary);
      border-color:cl(secondary);
      background: cl(secondary,0.1);
    }
  }
}
.add-link{
  font-weight: 700;
  font-size: rem(14px);
  line-height: rem(26px);
}