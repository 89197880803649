.modal{
  background-color: cl(black, 0.7);
  .modal-dialog {
    max-width: rem(600px);

  }
  .modal-content {
    border: 0;
    border-radius: 0;
    position: relative;
    padding: 0;
    background-color: cl(primary);
  }
  .close {
    margin: 0;
    position: absolute;
    top: rem(20px);
    right: rem(20px);
    width: rem(18px);
    height: rem(18px);
    overflow: hidden;
    text-indent: -999px;
    background: url(../img/close-white.png) no-repeat 50% 50%;
    background-size: contain;
    padding: 0;
    opacity: 1;
    z-index: 10;
    &:focus{
      outline: none;
    }
  }
  .modal-header{
    border: 0;
    padding: 2rem 3rem 0.5rem 3rem;
    color: cl(white);
    justify-content: center;
    .modal-title{
      color: cl(white);
      text-align: center;
    }
  }
  .modal-body{
    padding: 1rem 3rem;
    .form-label{
      color: cl(white);
      font-size: rem(16px);
    }

  }
  .buttons-type-multiselect{
    margin-bottom: 1.5rem;
    .item{
      position: relative;
      width: 100%;
      height: 100%;
      .rad {
        position: absolute;
        opacity: 0;
        right: 0;
        top: 50%;
      }
      .rad + label{
        height: 100%;
        background-color:  transparent;
        border: 0.0625rem solid cl(black);
        border-radius: rem(2px);
        color: cl(black);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: rem(14px);
        font-weight: 700;
        min-height: rem(47px);
        padding: 0.625rem 0;
        flex-grow: 1;
        margin-bottom: 0;
        &:hover{
          cursor: pointer;
        }
        p{
          margin-bottom: 0;
          font-size: rem(12px);
        }
        .link{
          width: 100%;
          margin: -0.625rem 0;
          color: cl(black);
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover{
            text-decoration: none;
          }
        }
      }
      .rad:hover + label {
        //background: cl(complimentary,10%);
      }
      .rad:checked + label {
        border: 0.0625rem solid cl(black);
        background: cl(black);
        color: cl(primary);
        .link{
          color: cl(primary);
        }
      }


    }
  }
}

//gallery popup
.popup {
  z-index: 9999;
  position: fixed;
  top:-9999px;
  left:0;
  width:100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background: cl(black,0.8);
  visibility: hidden;
  pointer-events: none;
  &.show {
    top:0;
    visibility: visible;
    pointer-events:all;
    .close-popup {
      display: block;
    }
  }
  .popup-content {
    width:100%;
   // max-width:51.188rem;
    max-height: 100%;
    position: relative;
    @include media-breakpoint-down(sm) {
    position: static;
  }
  }
  .popup-back{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .close-popup {
    border:0;
    background: none;
    position: fixed;
    width:1.875rem;
    display: none;
    top:4rem;
    right:1rem;
    cursor: pointer;
    z-index: 12;
    @include media-breakpoint-down(sm) {
    top:-2rem;
    right:-2rem;
  }
    @include media-breakpoint-down(sm) {
    right:1rem;
    top:1rem;
  }
    img {
      width:100%;
      height: auto;
    }
  }
  .slider-holder {
    .slider-for {
      position: relative;
      z-index: 5;
      .item-inner{
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      p,
      figcaption{
        display: block;
        text-align: center;
        margin:0;
        margin-top:1.4rem;
        color:cl(white);
        font-size:rem(20px);
        @extend .ttu;
        font-weight: 900;
        font-family: $font;
      }
      .slick-slide {
        &:focus {
          outline: none;
        }
        img{
          //height:rem(600px);
          height: 100%;
          width: 100%;
          max-height: 85vh;
          object-fit: contain;
          object-position: center;
          max-width: 90%;
          margin: 0 auto;
        //  @include media-breakpoint-down(md) {
        //  height: 38.875rem;
        //}
        //  @include media-breakpoint-down(sm) {
        //  height: 33.875rem;
        //}
          @include media-breakpoint-down(xs) {
            max-width: 100%;
        }
        }
        .video{
          position: relative;
          padding-bottom:56.25%;
          //padding-top:25px;height:0;
          z-index: 4;
          @include media-breakpoint-down(sm) {
          padding-top: 1rem;
        }
        }
        iframe {
          position:absolute;
          top:0;
          left:0;
          width:100%;
          height:100%;
        }
        video {
          display: block;
          max-width:100%;
          height: auto;
          &:focus {
            outline: none;
          }
        }
      }
    }

    .slick-next,
    .slick-prev {
      border:0;
      background: none;
      position: absolute;
      left:0;
     // top:17rem;
      @extend .pos-centerY;
      cursor: pointer;
      width:2rem;
      height: 2.5rem;
      font-size:rem(40px);
      color:cl(white);
      z-index: 10000;
      @include media-breakpoint-down(sm) {
      left:-2rem;
    }
      @include media-breakpoint-down(sm) {
      left:0;
      top:10rem;
    }
    }
    .slick-next {
      left: auto;
      right:0;
      @include media-breakpoint-down(sm) {
      right:-2rem;
    }
      @include media-breakpoint-down(sm) {
      right:0;
      top:10rem;
    }
    }
  }
}