
@font-face {
  font-family: 'Norican';
  src: url('../fonts/Norican-Regular.eot');
  src: url('../fonts/Norican-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Norican-Regular.woff2') format('woff2'),
  url('../fonts/Norican-Regular.woff') format('woff'),
  url('../fonts/Norican-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'bhatoshine';
  src: url('../fonts/bhatoshine-Regular.eot');
  src: url('../fonts/bhatoshine-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/bhatoshine-Regular.woff2') format('woff2'),
  url('../fonts/bhatoshine-Regular.woff') format('woff'),
  url('../fonts/bhatoshine-Regular.ttf') format('truetype'),
  url('../fonts/bhatoshine-Regular.svg#bhatoshine-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}



@font-face {
  font-family: 'Gotham';
  src: url('Gotham-Book.eot');
  src: local('../fonts/Gotham Book'), local('Gotham-Book'),
  url('../fonts/Gotham-Book.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Gotham-Book.woff2') format('woff2'),
  url('../fonts/Gotham-Book.woff') format('woff'),
  url('../fonts/Gotham-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('Gotham-Bold.eot');
  src: local('../fonts/Gotham Bold'), local('Gotham-Bold'),
  url('../fonts/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Gotham-Bold.woff2') format('woff2'),
  url('../fonts/Gotham-Bold.woff') format('woff'),
  url('../fonts/Gotham-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('Gotham-Medium.eot');
  src: local('../fonts/Gotham Medium'), local('Gotham-Medium'),
  url('../fonts/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Gotham-Medium.woff2') format('woff2'),
  url('../fonts/Gotham-Medium.woff') format('woff'),
  url('../fonts/Gotham-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}


@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Black.eot');
  src: local('../fonts/Gotham Black'), local('Gotham-Black'),
  url('../fonts/Gotham-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Gotham-Black.woff2') format('woff2'),
  url('../fonts/Gotham-Black.woff') format('woff'),
  url('../fonts/Gotham-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

