/* banner start */
.banner {
  position: relative;
  height: 100vh;
  @include media-breakpoint-down(xs) {
    height: 17.5rem;
    margin-top: 4.75rem;
  }
  &-video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    overflow: hidden;
    height: calc(100vh - 4rem);
    @include media-breakpoint-down(xs) {
      height: 15rem;
    }
    &:before {
      content: '';
      position: absolute;
      @extend .full;
      background: cl(black, 0.4);
      z-index: 2;
    }
    video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-images {
    position: absolute;
    width:100%;
    top:0;
    left:0;
    &-item {
      display: block;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      position: relative;
      height: calc(100vh - 4rem);
      @include media-breakpoint-down(xs) {
        height: 15rem;

      }
    }
    &-prev,
    &-next {
      @extend .pos-centerY;
      left:1rem;
      color:cl(white);
      background: none;
      border:0;
      font-size: rem(30px);
      cursor: pointer;
      z-index: 200;
      @include media-breakpoint-down(xs) {
        padding: rem(5px);
        font-size: rem(20px);
        text-shadow: 0 2px 5px cl(black,.5);
      }
      &:focus{
        outline: none;
      }
    }
    &-next {
      left:auto;
      right:1rem;
    }
  }
  &-text {
    position: relative;
    width:100%;
    height: 100%;
    color:cl(white);
    z-index: 6;
    display: flex;
    justify-content: center;
    align-items: center;
    .ttl {
      display: block;
      font-size: rem(60px);
      line-height: rem(80px);
      margin: rem(0 0 5px);
      font-weight: 700;
    }
    .txt {
      display: block;
      font-size: rem(22px);
      line-height: rem(50px);
      margin: rem(0 0 30px);
    }
    p {
      margin: 0;
    }
    .banner-block-ttl{
      color: cl(primary);
      text-align: center;
      margin-bottom: 1.25rem;
      .first-line{
        font-family: $font-b;
        font-size: rem(90px);
        color: cl(white);
        text-transform: none;
        font-weight: 700;
        line-height: rem(90px);
        margin-bottom: 0;
      }
      .second-line{
        font-family: $font;
        font-weight: 900;
        font-style: normal;
        font-size: rem(80px);
        color: cl(white);
        line-height: rem(80px);
       text-transform: none;
        margin-bottom: 0;
        letter-spacing: 0.02em;
      }
    }

    &__image{
      display: block;
      margin:0 auto;
      width: rem(600px);
      max-width: 100%;
      @include media-breakpoint-down(xs){
        width: rem(280px);
      }
    }
  }
  .ribbon-wrap{
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    z-index: 10;
    width: 100%;
  }
  .banner-ribbon-block{
    position: relative;
    width: rem(1075px);
    max-width: calc(100vw - 25rem);
    padding-bottom: 7%;
    border-radius: rem(3px);
    background-color: cl(yellow-bg);
    box-shadow: 1px 17px 24px 0px rgba(0, 0, 0, 0.1);
    @include media-breakpoint-down(md) {
      padding-bottom: 9.7vw;
      width: 88vw;
      max-width:100vw;
    }
    &:focus{
      outline: none;
    }
    &:before{
      content: "";
      @extend .pos-centerY;
      height: 120%;
      width: rem(80px);
      left: rem(-73px);
      background-image: url("../img/ribon-left.svg");
      background-size: contain;
      background-position: 100% 50%;
      background-repeat: no-repeat;
      @include media-breakpoint-down(md) {
        width: 8vw;
        left: -7.5vw;
      }
    }
    &:after{
      content: "";
      @extend .pos-centerY;
      height: 120%;
      width: rem(80px);
      right: rem(-73px);
      background-image: url("../img/ribon-right.svg");
      background-size: contain;
      background-position: 0 50%;
      background-repeat: no-repeat;
      @include media-breakpoint-down(md) {
        width: 8vw;
        right: -7.5vw;
      }
    }
    img{
      //width: rem(670px);
      //height: rem(220px);
      position: absolute;
      @extend .pos-centerX;
      bottom: 0;
      width: 100%;
    }
  }
  &--page {
    height: rem(500px);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    background-attachment: fixed;

    @include media-breakpoint-down(md){
      background-attachment: scroll;
    }
    @include media-breakpoint-down(sm){
      height: 19rem;
    }
    .ribbon-wrap{
      bottom: rem(-64px);
      @include media-breakpoint-down(lg) {
        bottom: -4.85vw;
      }
    }
    .floating-btn{
      bottom: -3rem;
      @include media-breakpoint-down(md){
        position: fixed;
        right: 0;
        bottom: 0;
        width: 100vw;
      }
      &.on-scroll{
        position: fixed;
        bottom: 1.5rem;
        right: rem(40px);
        @include media-breakpoint-down(lg){
          right: rem(30px);
        }
        @include media-breakpoint-down(md){
          position: fixed;
          right: 0;
          bottom: 0;
          width: 100vw;
        }
      }
    }

    //&:before {
    //  content: '';
    //  @extend .full;
    //  background: cl(black, 0.4);
    //  z-index: 2;
    //  @include media-breakpoint-down(sm){
    //    background: cl(black, 0.2);
    //  }
    //}

    .banner-text{
      &__image{
        display: block;
        margin:0 auto;
       // width: rem(385px);
        width: 100%;
        height: 100%;
        max-width: rem(530px);
        object-fit: contain;
        max-height: rem(214px);
     //   animation: animation-pulse-2 6s infinite linear;
        @include media-breakpoint-down(xs){
          width: 15.5rem;
        }
      }
    }
  }
  &-slider{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .item{
      height: rem(500px);
      @include media-breakpoint-down(sm){
        height: 19rem;
      }
      &-pic{
        width: 100%;
        height: 100%;
        object-fit: cover;
        animation: animation-pic  12s linear ;
      }
    }
    .slick-dots {
      position: absolute;
      @extend .pos-centerX;
      bottom: 1rem;
      display: flex;
      justify-content: center;
      padding-right: 15px;
      padding-left: 15px;
      margin: 0;
      z-index: 10;
      li {
        list-style: none;
        margin:rem(0 12px 0 0);
        padding: 0;
        &:before{
          display: none;
        }
        button {
          border:0;
          width: rem(10px);
          height: rem(10px);
          padding: 0;
          overflow: hidden;
          text-indent: -999px;
          border-radius: 50%;
          flex-shrink: 0;
          background: #afafaf;
          &:focus{
            outline: none;
          }
        }
        &.slick-active {
          button {
            background: cl(primary);
          }
        }
      }
    }
  }
}
/* banner end */

