.career-form-section{
  padding: rem(90px 0 90px 0);
  background-color: #f8f8f8;
  @include media-breakpoint-down(sm) {
    padding: rem(60px 0 90px 0);
  }
  .form{
    margin: 0 auto;
    max-width: rem(760px);
    width: 100%;
    .btn{
      width: rem(220px);
    }
  }
  .upload-resume-block{
    border: 1px dashed rgb(53, 55, 67);
    border-radius: rem(4px);
    margin-bottom: 1.75rem;
    .before-block{
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding: rem(15px 30px 15px 60px);
      position: relative;
      .pic{
        position: absolute;
        @extend .pos-centerY;
        width: rem(22px);
        left:1rem;
      }
      a{
        color: #353743;
        font-size: rem(18px);
        font-weight: 700;
      }
    }
    .after-block{
      display: flex;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      padding: rem(15px 40px 15px 60px);
      position: relative;
      .pic{
        position: absolute;
        @extend .pos-centerY;
        width: rem(22px);
        left:1rem;
      }
      p{
        color: #353743;
        font-size: rem(18px);
        font-weight: 700;
        margin-bottom: 0;
      }
      .del{
        position: absolute;
        @extend .pos-centerY;
        right:1rem;
        img{
          width: rem(18px);
        }
      }
    }
  }
}

.franchise-form-section{
  padding: rem(30px 0 90px 0);
  background-color: #f8f8f8;
  .form{
    margin: 0 auto;
    max-width: rem(760px);
    width: 100%;
    .btn{
      width: rem(220px);
    }
  }
  .upload-resume-block{
    border: 1px dashed rgb(53, 55, 67);
    border-radius: rem(4px);
    margin-bottom: 1.75rem;
    .before-block{
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding: rem(15px 30px 15px 60px);
      position: relative;
      .pic{
        position: absolute;
        @extend .pos-centerY;
        width: rem(22px);
        left:1rem;
      }
      a{
        color: #353743;
        font-size: rem(18px);
        font-weight: 700;
      }
    }
    .after-block{
      display: flex;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      padding: rem(15px 40px 15px 60px);
      position: relative;
      .pic{
        position: absolute;
        @extend .pos-centerY;
        width: rem(22px);
        left:1rem;
      }
      p{
        color: #353743;
        font-size: rem(18px);
        font-weight: 700;
        margin-bottom: 0;
      }
      .del{
        position: absolute;
        @extend .pos-centerY;
        right:1rem;
        img{
          width: rem(18px);
        }
      }
    }
  }
}
.accordion {
  .toggle-btn {
    border: 0;
    background-color: transparent;
    @extend .ttu;
    color: cl(black);
    font-family: $font;
    font-weight: 900;
    font-style: normal;
    font-size: rem(26px);
    width: 100%;
    text-align: left;
    line-height: rem(40px);
    padding: rem(5px 30px 5px 0);
    border-bottom: 2px solid cl(black);
    position: relative;

    &:focus {
      outline: none;
    }

    &:before {
      position: absolute;
      right: 0;
      content: "\f078";
      top: 1rem;
      transition: all .5s;
      font-family: Font Awesome\ 5 Free;
      font-weight: 900;
      font-size: rem(17px);
      color: cl(black);
    }

    &.collapsed {
      &:before {
        transform: rotate(-90deg);
      }
    }
  }
  .content-wrap{
    padding-top: 2rem;
    padding-bottom: 1.25rem;
  }
}

.buttons-type-multiselect{
  margin-bottom: 1.5rem;
  .item{
    position: relative;
    width: 100%;
  //  margin-bottom: 1rem;
    height: 100%;
    .rad {
      position: absolute;
      opacity: 0;
      right: 0;
      top: 50%;
    }
    .rad + label{
      height: 100%;
      background-color:  rgba(255, 255, 255, 0);
      border: 0.0625rem solid rgb(222, 222, 222);
      border-radius: rem(2px);
      color: #777777;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: rem(14px);
      font-weight: 700;
      min-height: rem(47px);
      padding: 0.625rem 0;
      flex-grow: 1;
      margin-bottom: 0;
      p{
        margin-bottom: 0;
        font-size: rem(12px);
      }
    }
    .rad:hover + label {
      //background: cl(complimentary,10%);
    }
    .rad:checked + label {
      border: 0.0625rem solid rgb(244, 175, 73);
      background: rgb(255, 255, 255);
      color: cl(black);
    }


  }
}

.menu-page-section{
  padding: rem(120px 0 70px 0);
  @include media-breakpoint-down(sm) {
    padding: rem(60px 0 70px 0);
  }
  .menu-grid{
  // display: grid;
   // grid-template-columns: repeat(4,1fr);
   // grid-gap: 1.25rem 1.25rem;
    padding-bottom: 2.1rem;
    display: flex;
    gap: 1.25rem 1.25rem;
    flex-wrap: wrap;
    @include media-breakpoint-down(md) {
     // grid-template-columns: repeat(2,1fr);
      max-width: 90%;
      margin: 0 auto;
      gap: 1.75rem 1.25rem;
    }
    //@include media-breakpoint-down(sm) {
    //  grid-template-columns: repeat(2,1fr);
    //}
    //@include media-breakpoint-down(xs) {
    //  grid-template-columns: repeat(1,1fr);
    //}
    .menu-item{
      width: calc(25% - 0.94rem);
      @include media-breakpoint-down(md) {
        width: calc(33% - 0.73rem);

      }
      @include media-breakpoint-down(sm) {
        width: calc(50% - 0.94rem);
      }
      @include media-breakpoint-down(xs) {
        width: 100%;
      }
      &-img{
        display: block;
        width: 100%;
        position: relative;
        padding-bottom: 100%;
        background-color: cl(gray,0.2);
        overflow: hidden;
        &:hover,
        &:focus {
          .pic{
            transform: scale(1.05);
          }
        }
        .pic{
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          transition: all 0.3s ease-in-out;
          object-fit: cover;
        }
      }
      &-content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: rem(20px 18px 10px 18px);
        h4 {
          font-size: rem(20px);
          margin: rem(0 0 20px);
          font-weight: 900;
          font-family: $font;
          @extend .ttu;
          text-align: center;
        }
        p{
          text-align: center;
          color: cl(gray);
          font-size: rem(15px);
          line-height: rem(25px);
          margin-bottom: 1.25rem;
        }
        .info-marker{
          text-align: center;
          font-size: rem(15px);
          line-height: rem(25px);
          font-weight: 700;
          color: cl(gray);
        }

      }
    }
  }
}

.map-section{
  height: rem(350px);
  width: 100%;
  position: relative;
  overflow: hidden;
  .map{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    img{
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.visit-section{
  padding: rem(108px 0 40px 0);
  @include media-breakpoint-down(sm){
    padding: rem(60px 0 40px 0);
  }
  .sub-ttl{
    @extend .ttu;
    font-family: $font;
    font-size: rem(30px);
    text-align: center;
    font-weight: bold;
    font-style: normal;
    max-width: rem(1050px);
    margin: 0 auto;

  }
  .addresses-grid{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 2rem 4.375rem;
    padding-bottom: 2.1rem;
    margin-top: rem(110px);
    @include media-breakpoint-down(md){
      padding: 0 2rem 2.1rem 2rem;
      margin-top: rem(60px);
      grid-template-columns: repeat(1,1fr);
    }
    .item{
      background-color: cl(black);
      padding: rem(33px 5px 33px 0);
      color:cl(white);
      display: flex;
      flex-wrap: wrap;
      position: relative;
      align-items: flex-start;
      @include media-breakpoint-down(xs){
        margin-top: 4rem;
        padding: rem(0 25px 170px 25px);
      }
      .pic-col{
        width: 50%;
        @include media-breakpoint-down(sm){
          display: flex;
          justify-content: center;
          flex-direction: column;
        }
        @include media-breakpoint-down(xs){
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 2rem;
        }
      }
      .text-col{
        width: 50%;
        padding-left: 3.5rem;
        @include media-breakpoint-down(sm){
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-left: 0;
        }
        @include media-breakpoint-down(xs){
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-left: 0;
        }
      }
      h3{
       color: cl(primary);
        @include media-breakpoint-down(sm){
          text-align: center;
        }
      }
      p{
        font-size: rem(16px);
        line-height: rem(28px);
        margin-bottom: 0;
        @include media-breakpoint-down(sm){
          text-align: center;
        }
      }
      dl{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: rem(16px);
        line-height: rem(26px);
        margin-bottom: 0.625rem;
        @include media-breakpoint-down(sm){
          justify-content: center;

        }
        dt {
          color:cl(white);
          font-size: rem(16px);
          width:rem(20px);
        }
        dd {
          margin: 0;
          @include media-breakpoint-down(sm){
          text-align: center;
          }
          a {
            color:cl(white);
          }
        }
      }
      .location-pic{
        width: rem(330px);
        height: rem(245px);
        object-fit: cover;
        position: relative;
        margin-left: -2rem;
        display: block;
        box-shadow: 1px 17px 76px 0px rgba(0, 0, 0, 0.29);
        @include media-breakpoint-down(xs){
          margin: 0 auto;
          margin-top: -4rem;
          width: 100%;
          height: auto;
        }
      }
      .btn{
        margin-left: rem(24px);
        margin-top: rem(40px);
      }
      .text-img{
        width: rem(180px);
        margin: 0 auto;
        display: block;
        margin-top: 1.5rem;
        @include media-breakpoint-down(xs){
          width: auto;
          height: 7rem;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 1.5rem;
        }
      }
    }
  }
}

.comments-section{
  background-color: #f8f8f8;
  padding: rem(56px 0 50px 0);
  .form{
    max-width: rem(766px);
    margin: 0 auto;
    @include media-breakpoint-down(xs){
      max-width: 90%;
    }
    textarea.form-control {
        height: rem(130px);
    }
    .btn{
      min-width: rem(225px);
      @include media-breakpoint-down(xs){
        width: 100%;
        margin-top: 2rem;
      }
    }
  }
}
.section-offset{
  text-align: center;
  padding: rem(90px 0 80px 0);
  @include media-breakpoint-down(sm){
    padding: rem(60px 30px 50px 30px);
  }
}
.catering-form-section{
  background-color: #f8f8f8;
  padding: rem(56px 3% 80px 9%);
  @include media-breakpoint-down(sm){
    padding: rem(30px 30px 50px 30px);
  }
  .form{
    max-width: rem(770px);
    @include media-breakpoint-down(sm){
      max-width: 100%;
    }
  }
  .menu-pic-wrap{
    margin-top: rem(-88px);
    background-color: cl(yellow-bg);
    box-shadow: 0.89px 16.977px 76px 0px rgba(0, 0, 0, 0.29);
    width: rem(777px);
    max-width: 100%;
    margin-left: auto;
    padding: 1.5rem;
    position: relative;
    @include media-breakpoint-down(sm){
      margin-top: 3rem;
      width: 100%;
    }
    img{
      width: 100%;
    }
  }
}

.franchise-owners-section{
  background-attachment: fixed;
  padding: rem(60px 0 80px 0);
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down(md) {
    background-attachment: scroll;
  }
}
.separation-section{
  img{
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}
.franchise-about-section{
  background-attachment: fixed;
  position: relative;
  padding: rem(108px 0 80px 0);
  text-align: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center right;
  @include media-breakpoint-down(md) {
    background-attachment: scroll;
    background-size: 100% auto;
    background-position: 0 -10%;
    background-image: none!important;
    padding: rem(98px 20px 80px 20px);
  }
  @include media-breakpoint-down(md) {
    padding: rem(58px 20px 80px 20px);
  }
  p{
    text-align: center;
    font-size: rem(16px);
    line-height: rem(26px);
    span{
      color: cl(primary);
      font-weight: 700;
    }
  }
  .deco{

    position: absolute;
    right: 0;
    height: 100%;
    width: auto;
    @extend .pos-centerY;
  }
}
.reg-about-section{
  background-attachment: fixed;
  position: relative;
  padding: rem(108px 0 80px 0);
  text-align: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center right;
  font-size: rem(16px);
  line-height: rem(26px);
  @include media-breakpoint-down(md) {
    background-attachment: scroll;
    background-size: 100% auto;
    background-position: 0 -10%;
    background-image: none!important;
    padding: rem(60px 20px 80px 20px);
  }
  p{
    text-align: center;
    font-size: rem(16px);
    line-height: rem(26px);
    span{
      color: cl(primary);
      font-weight: 700;
    }
  }
  .deco{

    position: absolute;
    right: 0;
    height: 100%;
    width: auto;
    @extend .pos-centerY;
  }
}
.ingrid-about-section{
  position: relative;
  padding: rem(60px 0 80px 0);
  text-align: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center right;
  font-size: rem(16px);
  line-height: rem(26px);
  @include media-breakpoint-down(md) {
    background-attachment: scroll;
    background-size: 100% auto;
    background-position: 0 -10%;
    background-image: none!important;
    padding: rem(60px 20px 80px 20px);
  }
  p{
    text-align: center;
    font-size: rem(16px);
    line-height: rem(26px);
    span{
      color: cl(primary);
      font-weight: 700;
    }
  }
  .deco{

    position: absolute;
    left: 0;
    height: 100%;
    width: auto;
    top:0;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}
.franchise-faq-section{
  padding: rem(50px 0 80px 0);
  .faq-block{
    max-width: rem(960px);
    margin: 0 auto;
    margin-top: 2.5rem;
  }
}

.common-toggle-btn {
  border: 0;
  background-color: cl(yellow-bg);
  color: cl(black);
  font-family: $font;
  font-size: rem(24px);
  font-weight: bold;
  font-style: normal;
  width: 100%;
  text-align: left;
  line-height: rem(40px);
  padding: rem(16px 50px 13px 22px);
  border-bottom: 3px solid cl(yellow-bg);
  position: relative;
  @include media-breakpoint-down(sm) {
    font-size: rem(22px);
    line-height: rem(32px);
  }
  @include media-breakpoint-down(xs) {
    font-size: rem(18px);
    line-height: rem(28px);
  }
  &.collapsed{
    background-color: #f8f8f8;
    border-bottom: 3px solid cl(white);
  }
  &:focus {
    outline: none;
    background-color: cl(yellow-bg);
    color: cl(black);
  }

  &:before {
    position: absolute;
    right: rem(22px);
    content: "\f078";
    top: 37%;
    transition: all .5s;
    font-family: Font Awesome\ 5 Free;
    font-weight: 900;
    font-size: rem(17px);
    color: cl(black);
  }

  &.collapsed {
    &:before {
      transform: rotate(-90deg);
    }
  }
}

  .collapse-content-wrap{
    border: 2px solid #f8f8f8;
    border-top: 0;
    padding: rem(32px 26px 10px 26px);
    background-color: cl(white);
    text-align: left;
    font-size: rem(16px);
    line-height: rem(26px);
    p{
      font-size: rem(16px);
      line-height: rem(26px);
      margin-bottom: 1.5rem;
    }
  }

  ul{
    margin-bottom: 0;
    list-style: none;
    li{
      font-size: rem(16px);
      line-height: rem(26px);
      padding: rem(5px 0 5px 25px);
      position: relative;
      &:before{
        content: "";
        position: absolute;
        left: 0;
        top:1rem;
        width: 3px;
        height: 3px;
        background-color: cl(primary);
        border-radius: 50%;
      }
    }
  }

  .overview-block{
    max-width: rem(960px);
    margin: 0 auto;
    margin-top: 1.5rem;
    .common-toggle-btn {
      text-align: center;
      padding: rem(16px 75px 13px 75px);
      .pic{
        position: absolute;
        left: 1.25rem;
        @extend .pos-centerY;
        height: rem(50px);
      }
    }
  }

.block-ttl-pic{
  display: block;
  margin:0 auto;
  width: 100%;
  height: 100%;
  max-width: rem(350px);
  object-fit: contain;
  max-height: rem(128px);
  margin-bottom: 1.25rem;
  @include media-breakpoint-down(xs){
    width: rem(280px);
  }
}

.section-with-right-pic-block{
  display: flex;
  width: 100%;
  align-items: stretch;
  position: relative;
  z-index: 2;
  background-color: transparent;
  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }
  &.section-with-left-pic-block{
    min-height: rem(800px);
  }
  .image-side{
    width: 50%;
    padding-bottom: 32%;
    position: relative;
    box-shadow: 0.89px 16.977px 76px 0px rgba(0, 0, 0, 0.29);
    background-color: cl(yellow-bg);
    @include media-breakpoint-down(md) {
      width: 100%;
      padding-bottom: 92%;
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
    .text__image{
      width: rem(390px);
      height: auto;
      z-index: 1;
      animation: animation-pulse 5s infinite linear;
      @extend .pos-center;
      margin: -7rem 0 0 -12rem;
      @include media-breakpoint-down(sm) {
        margin: -5rem 0 0 -9rem;
        width: rem(300px);
      }
    }
  }
  .content-side{
    width: 50%;
    background-color: cl(yellow-bg);
    padding: 3% 8% 4% 8.1%;
    text-align: center;
    margin: 3rem 0 3rem 0;
    color: cl(black);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: rem(16px);
    line-height: rem(26px);
    @include media-breakpoint-down(md) {
      order: 1;
      width: 100%;
      margin: 0;
      padding: 4rem 6% 4rem 6%;
    }
    .block-ttl{
      margin-bottom: 2rem;
      .first-line{
        color: cl(text-red);
        margin-bottom: 0;
      }
      .second-line{
        color: cl(black);
        margin-bottom: 0;
      }
    }
    .signature{
      font-family: $font-b;
      font-size: rem(40px);
      color: cl(black);
      text-transform: none;
      font-weight: 400;
      line-height: rem(40px);
      margin-bottom: 0;
      margin-top: 2.5rem;
    }
    p{
      font-size: rem(16px);
      line-height: rem(26px);
      margin-bottom: 1.65rem;
    }
    h3{
      margin-bottom: 2rem;
    }
    h4{
      margin-bottom: 2rem;
    }
  }
}