.footer {
  background-color: cl(yellow-bg);
  color:cl(white);
  position: relative;
  .deco{
    position: absolute;
    top: 0;
    height: rem(90px);
    left: 0;
    background-size: cover;
    border-bottom: 0.375rem solid black;
    width: 100%;

  }
  &-top{
    position: relative;
    z-index: 2;
    background-size: 100% auto;
    background-position: 50% 101%;
    background-repeat: no-repeat;
    padding: rem(24px 0 247px 0);
    @include media-breakpoint-down(md){
      padding: rem(26px 0 140px 0);
    }
    @include media-breakpoint-down(sm){
      padding: rem(26px 0 60px 0);
    }
  }
  &-bottom{
    background: cl(black);
    @include media-breakpoint-down(sm){
      padding: rem(36px 0 0px 0);
    }
  }
  .top-link-btn{
      border-radius: 50%;
      background-color: cl(primary);
      box-shadow: 0.872px 9.962px 18px 0px rgba(0, 0, 0, 0.29);
      position: absolute;
      width: rem(36px);
      height: rem(36px);
      z-index: 1;
      color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 1.5rem;
     left: 2.5rem;
    @include media-breakpoint-down(md){
     right: 1rem;
      left: unset;
      bottom: 6rem;
    }
    @include media-breakpoint-down(xs){
      bottom: 7rem;
    }
    img{
      width: rem(18px);
    }
  }
  &-title {
    font-size: rem(18px);
    line-height: rem(28px);
    color: cl(primary);
    @extend .ttu;
    //font-family: $font-j;
    margin-bottom: 0.625rem;
  }
  &-social {
    display: flex;
    align-items: center;
    margin-top: 2.5rem;
    @include media-breakpoint-down(xs){
      justify-content: center;
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      color:cl(white);
      margin-right: rem(20px);
      &:hover {
        text-decoration: none;
        color:cl(primary);
        border-color:cl(primary);
      }
      i {
        font-size: rem(18px);
      }
    }
  }
  .awards{
    margin-top: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-down(md){
      flex-direction: column;
    }
    .text{
      color: cl(black);
      font-family: $font;
      font-weight: bold;
      font-style: normal;
      font-size: rem(26px);
      line-height: rem(26px);
      width: calc( 50% - 22rem);
      @extend .ttu;
      white-space: nowrap;
      @include media-breakpoint-down(lg){
        white-space: normal;
      }
      @include media-breakpoint-down(md){
        margin-left: rem(0);
        margin-top: 2rem;
        width: 100%;
        text-align: center;
      }
    }
    .ttl{
      color: cl(white);
      text-align: center;
      width: calc( 50% - 22rem);
      @include media-breakpoint-down(md){
        margin-right: rem(0px);
        margin-bottom: 2rem;
        width: 100%;
      }
      .first-line{
        font-family: $font-b;
        font-size: rem(40px);
        color: cl(white);
        text-transform: none;
        font-weight: 400;
        line-height: rem(40px);
      }
      .second-line{
        font-family: $font;
        font-weight: 900;
        font-style: normal;
        font-size: rem(50px);
        color: cl(white);
        line-height: rem(40px);
      }
    }
    .pics{
      width: rem(725px);
      display: flex;
      justify-content: center;
      column-gap: 1rem;
      align-items: center;
      @include media-breakpoint-down(sm){
        width: 100%;

      }
      a{
        width: rem(123px);
        @include media-breakpoint-down(lg){
          width: rem(150px);
        }
        @include media-breakpoint-down(sm){
          width: 25%;
          height: 100%;
        }
      }
      img{
        width: 100%;

        object-fit: contain;
        @include media-breakpoint-down(sm){
          width: 100%;
        }
      }
    }
  }
  &-logo {
    display: block;
    margin: 0 auto;;
    width:rem(150px);
    img {
      width:100%;
      height: auto;
    }
  }
  &-contacts {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: rem(14px);
    line-height: rem(30px);
    margin-bottom: 0rem;
    @include media-breakpoint-down(xs){
      justify-content: center;
    }
    dt {
      color:cl(primary);
      font-size: rem(14px);
      width:rem(20px);
    }
    dd {
      margin: 0;
      a {
        color:cl(white);
      }
    }
  }
  &-copy {
    display: block;
    @extend .text-center;
    font-size: rem(14px);
    padding: rem(40px 0 20px 0);
    @include media-breakpoint-down(md){
      padding: rem(30px 0 80px 0);
    }
  }
  .offset{
    margin-bottom: 1.25rem;
    @include media-breakpoint-down(xs){
      text-align: center;
    }
    p{
      font-size: rem(14px);
      line-height: rem(24px);
      margin-bottom: 0;
      &.big-line{
        line-height: rem(30px);
      }
      &.footer-title {
        font-size: rem(18px);
        line-height: rem(28px);
        color: cl(primary);
        @extend .ttu;
        font-weight: bold;
        margin-bottom: 0.625rem;
      }
    }
  }
  .form {
    &-group {
      margin:rem(0 0 28px);
    }
    &-control,
    .custom-select {
      display: block;
      width:100%;
      font-family: $font;
      height: rem(50px);
      border-radius: rem(2px);
      background: cl(white);
      border:rem(1px solid cl(white));
      padding:rem(0 16px);
      font-size:rem(15px);
      color:$black;
      @include placeholder(#aeaeae);
      text-align: center;
    }
  }
}

