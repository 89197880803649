.header {
	position: fixed;
	left:0;
	top:0;
	width:100%;
	z-index: 1000;
	background: transparent;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: rem(13px 40px);
	transition: all .3s linear;
	font-family: $font;
	font-weight: 500;
	@include media-breakpoint-down(lg){
		padding: rem(13px 40px);
	}
	@include media-breakpoint-down(sm) {
		padding: rem(13px 30px);
	}
	@include media-breakpoint-down(xs) {
		height: 4.75rem;
		padding: rem(0 25px 0 20px);
		background-color: cl(black);
		align-items: center;
	}
	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		opacity: 0.7;
		height: 170px;
		background: url(../img/shadow.png) repeat-x;
		-webkit-transition: 350ms ease-in-out;
		-moz-transition: 350ms ease-in-out;
		transition: 350ms ease-in-out;
		@include media-breakpoint-down(sm) {
			top: -80%;
		}

	}
	&-scroll{
		background-color: cl(black,1);
		padding-top: rem(4px);
		padding-bottom: rem(4px);
		@include media-breakpoint-down(lg){
			padding-top: rem(4px);
			padding-bottom: rem(4px);
		}
		@include media-breakpoint-down(sm) {
			padding-top: rem(4px);
			padding-bottom: rem(4px);
		}
		.header-logo{
			img {
				width: rem(75px);
			}
		}
		.header-inner {
			transition: all .3s ease-in-out;
			@include media-breakpoint-up(lg) {
				padding-top: 2rem;
			}
		}
		&:before {
			display: none;
		}
	}
	&-logo {
		width:rem(135px);
		flex-shrink: 0;
		position: relative;
		@include media-breakpoint-down(sm) {
			width:rem(90px);
		}
		@include media-breakpoint-down(xs) {
			width: rem(65px);
		}
		img {
			max-width: 100%;
			height: auto;
			width:rem(135px);
			transition: all 0.3s linear;
			@include media-breakpoint-down(sm) {
				width:rem(90px);
			}
			@include media-breakpoint-down(xs) {
				width:rem(65px);
			}
		}
	}
	&-skip {
		color:white;
		text-align: center;
		position: relative;
		z-index: 2;
		&:focus {
			outline: 1px white dotted;
		}
	}
	&-hold {
		width:100%;
		position: relative;
		// z-index: 2;
		@include media-breakpoint-down(lg) {
			display: flex;
			// flex-direction: row-reverse;
			align-items: center;
			justify-content: flex-end;
			padding-top: 1.25rem;
		}
		@include media-breakpoint-down(xs) {
			padding: 0;
		}
	}
	&-inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		

		@include media-breakpoint-up(xl) {
			width:100%;
		}
		@include media-breakpoint-down(lg) {
			justify-content: flex-end;
		}
		@include media-breakpoint-up(sm) {
			padding-top: 1rem;
		}
	}
	&-nav {
		width:100%;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 2;
		@include media-breakpoint-down(lg) {
			position: fixed;
			right:-100%;
			top:0;
			height: 100%;
			margin: 0;
			width:30rem;
			padding: rem(210px 0 0);
			background: cl(black);
			display: flex;
			justify-content: flex-start;
			flex-direction: column;
			transition: all 0.3s linear;
			&.show {
				right:0;
				box-shadow: rem(5px 0 10px rgba(0,0,0,0.3));
			}
		}
		@include media-breakpoint-down(sm) {
			width:100%;
		}
		&-logo {
			display: none;
			position: absolute;
			top:1rem;
			@extend .pos-centerX;
			@include media-breakpoint-down(lg) {
				display: block;
				width: rem(150px);
			}
		}
		& > ul {
			display: flex;
			align-items: center;
			list-style: none;
			margin: 0;
			@include media-breakpoint-down(lg) {
				height: 100%;
				width:100%;
				overflow: auto;
				display: block;
			}
			& > li {
				position: relative;
				margin-left: rem(25px);
				padding: 0;
				&:before{
					display: none;
				}
				@include media-breakpoint-down(lg) {
					padding: 0;
					margin: 0;
					width:100%;
					border-top:rem(1px solid cl(white, 0.1));
					&:last-child {
						border-bottom:rem(1px solid cl(white, 0.1));
					}
				}
				& > a {
					display: block;
					font-size: rem(16px);
					line-height: rem(18px);
					font-weight: 700;
					color:cl(white);
					@extend .ttu;
					@include media-breakpoint-down(lg) {
						display: block;
						padding: 1.5rem 2rem;
						text-align: center;
					}
					&:hover {
						text-decoration: none;
						color:cl(primary);
					}
				}
				&.active {
					& > a {
						text-decoration: none;
						color:cl(primary);
					}
				}
				&:hover{
					.drop {
						display: block;
						@include media-breakpoint-down(lg) {
							display: none;
						}
					}
				}
				.drop {
					position: absolute;
					top:rem(17-x);
					padding-top: rem(21px);
					left:0;
					width:rem(200px);
					display: none;
					@include media-breakpoint-down(lg) {
						display: none;
						position: static;
						width:100%;
						padding: 0;
						margin: 0;
					}
					ul {
						margin: 0;
						background: cl(black);
						padding: rem(2px);
						list-style: none;
						box-shadow:rem(1px 1px 6px cl(black, .21));
						@include media-breakpoint-down(lg) {
							box-shadow: none;
							padding: 0;
							background: none;
						}
						li {
							padding: 0;
							&:before{
								display: none;
							}
							a {
								display: block;
								font-size: rem(15px);
								line-height: rem(18px);
								color:$white;
								padding: rem(10px 10px 10px 20px);
								@include media-breakpoint-down(lg) {
									display: block;
									padding: 1.5rem 2rem;
									text-align: center;
									border-top:rem(1px solid cl(white, .1));
								}
								&:hover {
									text-decoration: none;
									background: cl(primary);
									@include media-breakpoint-down(lg) {
										text-decoration: none;
										background: transparent;
										color: cl(primary);
									}
								}
							}
							&.active {
								a {
									text-decoration: none;
									background: cl(primary);
									@include media-breakpoint-down(lg) {
										text-decoration: none;
										background: transparent;
										color: cl(primary);
									}
								}
							}
						}
					}
				}
			}
		}
	}
	&-buttons {
		position: relative;
		display: flex;
		align-items: center;
		margin: rem(0 0 0 32px);

		.link {
			color:cl(white);
			font-size: rem(18px);
			margin-left: 1.5rem;
			@include media-breakpoint-down(sm) {
				margin-left: 2.5rem;
			}
			&:focus,
			&:hover {
				outline: none;
				text-decoration: none;
				color:cl(primary);
			}

		}

	}
	
	.hide-nav {
		color:white;
		text-align: center;
		background: none;
		border:0;
		&:focus {
			outline: 1px white dotted;
			padding: 5px;
			font-weight: 700;
		}
	}
}

/*hamburger btn*/
.btn-lines {
	position: absolute;
	display: block;
	left: 0;
	width: 100%;
	height:rem(2px);
	background-color: cl(primary);
	content: "";
	transition: all 0.3s ease;
}
.c-hamburger {
	background: none;
	border:0;
	display: none;
	position: relative;
	z-index: 10;
	margin-left: 2rem;
	padding: 0;
	height:rem(20px);
	width:rem(26px);
	flex-shrink: 0;
	font-size: 0;
	text-indent: -9999px;
	appearance: none;
	border: none;
	cursor: pointer;
	transition: all 0.3s ease  0.3s;
	&:focus {
		// outline: none;
	}
	@include media-breakpoint-down(lg){
		display: block;
	}
	@include media-breakpoint-up(sm) {
		// margin-top: 1.25rem;
	}
	span {
		display: block;
		position: absolute;
		top:rem(9px);
		left: 0;
		right: 0;
		height:rem(2px);
		background: cl(primary);
		transition: all 0.3s ease;
	}
	span::before {
		@extend .btn-lines;
		top:rem(-7px);
	}
	span::after {
		@extend .btn-lines;
		bottom:rem(-7px);
	}
	&--htx {
		background-color: transparent;
		&.active {
			&:before {
				display: none;
			}
			span {
				background: none;
			}
			span::before {
				top: 0;
				transform:rotate(45deg);
				//background: cl(white);
			}
			span::after {
				bottom: 0;
				transform:rotate(-45deg);
				//background: cl(white);
			}
		}
	}
}
/*hamburger btn*/
