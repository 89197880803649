.static {
  font-size: rem(16px);
  line-height: rem(30px);
  color:cl(text);
  p {
    margin: rem(0 0 20px);
  }
  h2 {
    font-size: rem(24px);
    margin: rem(0 0 20px);
    font-weight: 700;
  }
  h3 {
    @extend .ttu;
    font-family: $font;
    font-size: rem(30px);
    font-weight: 900;
  }
  h4 {
    font-size: rem(20px);
    margin: rem(0 0 20px);
    font-weight: 700;
  }
  h5 {
    font-size: rem(18px);
    margin: rem(0 0 20px);
    font-weight: 700;
  }
  h6 {
    font-size: rem(16px);
    margin: rem(0 0 20px);
    font-weight: 700;
  }
  ol, ul {
    margin: rem(0 0 20px);
    padding: rem(0 0 0 20px);
  }
  img {
    display: block;
    margin: 0 auto 2rem;
    max-width:90%;
    height: auto;
  }
}
.static-section{
  //background-attachment: fixed;
  position: relative;
  padding: rem(120px 0 80px 0);
  text-align: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center right;
  @include media-breakpoint-down(md) {
    background-attachment: scroll;
    background-size: 100% auto;
    background-position: 0 -10%;
    background-image: none!important;
    padding: rem(60px 20px 80px 20px);
  }
  p{
    text-align: center;
    font-size: rem(16px);
    line-height: rem(26px);
    span{
      color: cl(primary);
      font-weight: 700;
    }
  }
  ul{
    li{
      text-align: left;
    }
  }
}
