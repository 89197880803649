.home{
  &-full-menu-section{
    padding: rem(18px 0 70px 0);
    text-align: center;
  }
  &-about-section{
    display: flex;
    width: 100%;
    align-items: stretch;
    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
    }
    &.catering-section{
      margin-top: rem(-75px);
      .content-side{

        margin: rem(75px 0 27px 0);
        @include media-breakpoint-down(md) {
          margin: rem(0 0 27px 0);
        }
      }
    }
    .image-side{
      width: 50%;
      padding-bottom: 42%;
      position: relative;
      box-shadow: 0.89px 16.977px 76px 0px rgba(0, 0, 0, 0.29);
      background-color: rgb(244, 175, 73);

      @include media-breakpoint-down(md) {
        width: 100%;
        padding-bottom: 67%;
      }
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
      .video-holder{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        overflow: hidden;
        height: 100%;
        z-index: 2;
        //&:before {
        //  content: '';
        //  position: absolute;
        //  @extend .full;
        //  background: cl(black, 0.4);
        //  z-index: 2;
        //}
        video {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .text__image{
        width: rem(390px);
        height: auto;
        z-index: 1;
        animation: animation-pulse 5s infinite linear;
        @extend .pos-center;
        margin: -7rem 0 0 -12rem;
        @include media-breakpoint-down(sm) {
          margin: -5rem 0 0 -9rem;
          width: rem(300px);
        }
      }
    }
    .content-side{
      width: 50%;
      background-color: cl(gray-light);
      padding: 4% 6% 4% 8%;
      text-align: center;
      margin: 3rem 0 3rem 0;
      @include media-breakpoint-down(md) {
        order: 1;
        width: 100%;
        margin: 0;
        padding: 4rem 6% 4rem 8%;
      }
        font-size: rem(16px);
        line-height: rem(26px);
      p{
        margin-bottom: 1.65rem;
      }
    }
  }
  &-menu-slider{
    margin-top: 3.75rem;
    .slick-prev,
    .slick-next {
      border:0;
      background: none;
      position: absolute;
      color: cl(black);
      font-size:rem(28px);
      flex-shrink: 0;
      left: -2rem;
      @extend .pos-centerY;
      cursor: pointer;
      @include media-breakpoint-down(md) {
        font-size:rem(30px);
        left: 0;
      }
      &:hover {
        color: cl(primary);
      }

    }
    .slick-next {
      left: unset;
      right: -2rem;
      @include media-breakpoint-down(md) {
        right: 0;
      }
    }
    .item{
      //padding: 0 3rem;
      display: block;
      img{
        width: rem(160px);
        height: rem(160px);
        max-width: 100%;
        object-fit: contain;
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }
    }
  }
  &-menu-slider-2{
    margin-top: 4rem;
    .slick-prev,
    .slick-next {
      border:0;
      background: none;
      position: absolute;
      color: cl(black);
      font-size:rem(28px);
      flex-shrink: 0;
      left: -2rem;
      @extend .pos-centerY;
      cursor: pointer;
      z-index: 2;
      @include media-breakpoint-down(md) {
        font-size:rem(35px);
        left: 0;
      }
      &:hover {
        color: cl(primary);
      }

    }
    .slick-next {
      left: unset;
      right: -2rem;
      @include media-breakpoint-down(md) {
        right: 0;
      }
    }
    .item{
      display: block;
      img{
        margin: 0 auto;
        width: rem(380px);
        height: rem(380px);
        max-width: 100%;
        object-fit: cover;
        @include media-breakpoint-down(lg) {
          width: rem(330px);
          height: rem(330px);
        }
        @include media-breakpoint-down(md) {
          width: rem(300px);
          height: rem(300px);
        }
        @include media-breakpoint-down(sm) {
          width: 100%;
          height: 100%;
        }
      }
    }
  }


  &-second-section{
    padding: rem(50px 0 140px 0);
    text-align: center;
  }
  &-insta-section{
    padding: rem(90px 10px 0px 10px);
    text-align: center;
    .insta-grid{
      display: grid;
      grid-template-columns: repeat(8,1fr);
      grid-gap: 0.625rem 0.625rem;
      @include media-breakpoint-down(sm) {
        display: none;
      }
      .item{
        width: 100%;
        padding-bottom: 100%;
        display: block;
        position: relative;
        margin-bottom: 0.625rem;
        background-color: cl(primary);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        img{
          width: 100%;
          height: 100%;
          position: absolute;
          top:0;
          left: 0;
          object-fit: cover;
        }
      }
    }
    .insta-grid-slider{
      display: none;
      margin-top: 4rem;
      @include media-breakpoint-down(sm) {
        display: block;
      }
      .column{
        margin: 0.625rem;
      }
      .slick-prev,
      .slick-next {
        border:0;
        background: none;
        position: absolute;
        color: cl(white);
        font-size:rem(20px);
        flex-shrink: 0;
        right: 4rem;
        top: -3rem;
        cursor: pointer;
        border-radius: 50%;
        background-color: cl(primary);
        width: 1.75rem;
        height: 1.75rem;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        @include media-breakpoint-down(md) {
          font-size:rem(16px);
        }
        &:hover {
          color: cl(white);
        }

      }
      .slick-next {
        right: 0;
      }
      .item{

        width: 100%;
        padding-bottom: 100%;
        display: block;
        position: relative;
        background-color: cl(primary);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: 0.625rem;
        img{
          width: 100%;
          height: 100%;
          position: absolute;
          top:0;
          left: 0;
          object-fit: cover;
        }
      }
    }
  }
}
.testimonials-section {
  &.testimonials-section-home {
      padding: rem(140px 0 140px 0);
      color: cl(white);
    .block-ttl{
      .second-line{
        color: cl(white);
      }
    }
  }
}