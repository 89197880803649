.row:before, .row:after {display: none !important;}
* {
	margin:0;
	padding: 0;
}
html,
body {
	color: cl(text);
	min-height: 100vh;
	background: cl(white);
	font-family:$font;
	font-weight: normal;
	font-style: normal;
	line-height: 1.5;
	overflow-x: hidden;
	@include rem-baseline;
	font-size: calc(10.75px + 15 * ((100vw - 1200px) / 2048));
	@include media-breakpoint-down(lg) {
		font-size: calc(12px + 4 * ((100vw - 576px) / 1199.98));
	}
	@include media-breakpoint-down(xs) {
		font-size: calc(13px + 2 * ((100vw - 320px) / 575.98));
	}
	&.modal-open{
		padding: 0!important;
	}
	scroll-behavior: smooth;
	strong{
		font-weight: bold;
		font-style: normal;
	}
}
img {
	vertical-align: top;
}
a {
	color: cl(primary);
	text-decoration: none;
	&:hover {
		color: cl(primary);
		text-decoration: underline;
	}
}
.overlay {
	padding-top: 0;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	z-index: 10;
	display:none;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 100;
	}
	&.show {
		display: block;
	}
}
.container {
	--size: 78.125rem;
	max-width: var(--size);
	&--small {
		--size: 50rem;
	}
	&--middle{
		--size: 84rem;
	}
	&--big{
		--size: 96.75rem;
	}
}
.wrapper {
	padding: rem(0 190px);
}
.loader {
	position: fixed;
	left:0;
	top:0;
	width:100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
	background: cl(white);
}

h1 {
	font-size: rem(60px);
	margin: rem(0 0 20px);
	font-family: $font;
	font-weight: 900;
	font-style: normal;
	line-height: rem(40px);
	@extend .ttu;
	color: cl(black);
}
h2 {
	font-size: rem(40px);
	margin: rem(0 0 20px);
	font-family: $font;
	font-weight: 900;
	font-style: normal;
	line-height: rem(36px);
	@extend .ttu;

	color: cl(black);
}
h3 {
	font-size: rem(34px);
	margin: rem(0 0 20px);
	font-family: $font;
	font-weight: 900;
	font-style: normal;
	line-height: rem(34px);
	@extend .ttu;
	color: cl(black);
}

h4 {
	font-size: rem(30px);
	margin: rem(0 0 20px);
	font-family: $font;
	font-weight: bold;
	font-style: normal;
	line-height: rem(32px);
	@extend .ttu;
	color: cl(black);
}
h5 {
	font-size: rem(26px);
	line-height: rem(28px);
	margin: rem(0 0 20px);
	font-family: $font;
	font-weight: bold;
	font-style: normal;
	@extend .ttu;
	color: cl(black);
}
h6 {
	font-size: rem(18px);
	line-height: rem(26px);
	margin: rem(0 0 20px);
	font-family: $font;
	font-weight: bold;
	font-style: normal;
}



.block-ttl{
	display: block;

	color: cl(primary);
	text-align: center;
	margin-bottom: 1.25rem;
	&:hover{
		text-decoration: none;
	}
	.first-line{
		font-family: $font-b;
		font-size: rem(40px);
		color: cl(primary);
		font-weight: normal;
		font-style: normal;
		font-display: swap;
		line-height: rem(56px);
		margin-bottom: 0;
		text-transform: capitalize;
	}
	.second-line{
		font-family: $font;
		font-weight: 900;
		font-style: normal;
		font-size: rem(50px);
		color: cl(black);
		line-height: rem(60px);
		@extend .ttu;
		margin-bottom: 0;
	}
}


@keyframes animation-pulse {
	0% {
		transform: rotate(-1deg) scale(1);
	}
	50% {
		transform: rotate(1deg) scale(1.1);
	}
	100% {
		transform: rotate(-1deg) scale(1);
	}
}
@-webkit-keyframes animation-pulse{
	0% {
		transform: rotate(-1deg) scale(1);
	}
	50% {
		transform: rotate(1deg) scale(1.1);
	}
	100% {
		transform: rotate(-1deg) scale(1);
	}
}

@keyframes animation-pulse-2 {
	0% {
		transform: rotate(-1deg) scale(1);
	}
	50% {
		transform: rotate(1deg) scale(1.05);
	}
	100% {
		transform: rotate(-1deg) scale(1);
	}
}
@-webkit-keyframes animation-pulse-2{
	0% {
		transform: rotate(-1deg) scale(1);
	}
	50% {
		transform: rotate(1deg) scale(1.05);
	}
	100% {
		transform: rotate(-1deg) scale(1);
	}
}

@keyframes zoom {
	0% {
		transform:  scale(1);
	}
	50% {
		transform: translateY(-5px) scale(1.02);
	}
	100% {
		transform:  scale(1);
	}
}
@-webkit-keyframes zoom{
	0% {
		transform:  scale(1);
	}
	50% {
		transform: translateY(-5px) scale(1.02);
	}
	100% {
		transform:  scale(1);
	}
}


@keyframes animation-pic {
	from{
		transform: scale(1.1);
	}
	to {
		transform: scale(1);
	}
}

@-webkit-keyframes round-animation {
	from {
		opacity: 0.1;
		transform: scale3d(1.0, 1.0, 1.0);
	}
	50% {
		opacity: 1;
	}
	70% {
		opacity: 1;
	}
	100% {
		opacity: 0.1;
		transform: scale3d(1.3, 1.3, 1.3);
	}
}

@keyframes round-animation {
	from {
		opacity: 0.1;
		transform: scale3d(.9, 0.9, 0.9);
	}
	50% {
		opacity: 0.6;
	}
	70% {
		opacity: 0.56;
	}
	100% {
		opacity: 0.1;
		transform: scale3d(1.1, 1.1, 1.1);
	}
}

.round-animation {
	animation: round-animation 3.0s infinite ease;
}

.floating-btn {
	bottom: 1rem;
	right: rem(50px);
	position: absolute;
	z-index: 100;
	transition: all 0.3s ease-in-out;
	@include media-breakpoint-down(md){
		position: fixed;
		right: 0;
		bottom: 0;
		width: 100vw;
	}
	&:hover{
		text-decoration: none;
		.btn-inner{
			background-color: cl(primary);
		}
		.round {
			&-item {
				&-1 {
					background-color: cl(primary);
				}

			}
		}
	}
	&.on-scroll{
		position: fixed;
		bottom: 1.5rem;
		right: rem(40px);
		@include media-breakpoint-down(lg){
			right: rem(30px);
		}
		@include media-breakpoint-down(md){
			position: fixed;
			right: 0;
			bottom: 0;
			width: 100vw;
		}
	}
	.btn-inner{
		background-color: rgb(239, 65, 35);
		width: rem(108px);
		height: rem(108px);
		border-radius: 50%;
		position: relative;
		z-index: 4;
		color: #fff;
		font-size: rem(19px);
		line-height: rem(23px);
		letter-spacing: 0.15em;
		text-transform: uppercase;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0;
		text-align: center;
		box-shadow: 0.89px 16.977px 24px 0px rgba(0, 0, 0, 0.1);
		font-weight: 900;
		font-style: normal;
		transition: all 0.3s ease-in-out;
		@include media-breakpoint-down(lg) {
			font-size: rem(15px);
			width: rem(100px);
			height: rem(100px);
		}
		@include media-breakpoint-down(md){
			width: 100%;
			height: rem(50px);
			border-radius: rem(2px);
			font-size: rem(16px);
		}
		&:hover{
			text-decoration: none;
			color: cl(white);

		}
	}
	.round{
		&-item {
			z-index: 1;
			position: absolute;
			left: 50%;
			top: 50%;
			border-radius: 50%;
			opacity: 0;
			&-1 {
				width: rem(126px);
				height: rem(126px);
				margin: rem(-63px 0 0 -63px);
				box-shadow: 0.89px 16.977px 24px 0px rgba(0, 0, 0, 0.1);
				animation-delay: 0.3s;
				background-color: rgb(239, 65, 35);
				opacity: 0.529;
				transition: all 0.3s ease-in-out;
				@include media-breakpoint-down(lg){
					width: rem(116px);
					height: rem(116px);
					margin: rem(-58px 0 0 -58px);
				}
				@include media-breakpoint-down(md){
					display: none;
				}
			}

		}
	}

}


